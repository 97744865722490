<template>
    <div>
        <div v-if="type == 'video' && uploadList && uploadList.length > 0" style="display: inline-block">
            <div class="demo-upload-list" v-for="(item, index) in uploadList" :key="index">
                <template v-if="item.status === 'finished' && item.url">
                    <video :src="item.url" />
                    <div class="demo-upload-list-cover">
                        <Icon type="ios-eye-outline" @click.native="handleView(item.url)" v-if="showView"></Icon>
                        <Icon v-if="showDel" type="ios-trash-outline" @click.native="handleRemove(item)"></Icon>
                    </div>
                </template>
                <template v-else>
                    <Progress v-if="item.showProgress" :percent="item.percentage" hide-info></Progress>
                </template>
            </div>
        </div>
        <!-- 视频 -->
        <Upload
            v-if="type == 'video'"
            ref="upload"
            :headers="uploadHeaders"
            :data="uploadData"
            :show-upload-list="false"
            :default-file-list="defaultList"
            :on-success="handleSuccess"
            :format="['mp4', 'mpeg', 'avi', '3gp', 'wmv']"
            accept=".mp4,.mpeg,.avi,.3gp,.wmv"
            :max-size="maxSize"
            :on-format-error="handleFormatError"
            :on-exceeded-size="handleMaxSize"
            :before-upload="handleBeforeUpload"
            :multiple="multiple"
            type="drag"
            :action="action"
            :style="{ display: 'inline-block', width: fileLength == uploadList.length ? '0px' : '80px' }"
        >
            <div style="width: 80px; height: 80px; line-height: 80px" v-if="fileLength == uploadList.length ? false : true">
                <Icon type="md-add" size="60" style="color: #bbb; margin: 8px auto"></Icon>
            </div>
        </Upload>
        <Modal title="查看视频" v-model="visible" footer-hide v-if="type == 'video' && showView">
            <video controls :src="visibleUrl" v-if="visible" style="width: 100%" />
        </Modal>
    </div>
</template>

<script>
import LiefengModal from "@/components/LiefengModal"
export default {
    props: {
        type: {
            //上传视频还是文件
            type: String,
            default: "video", //video , file ,
        },
        // accept: {
        //     type: String,
        //     default: ,
        // },
        multiple: Boolean, //是否允许多选，默认false
        maxSize: Number, //文件限制大小
        // format: {
        //     type: Array,
        //     default:,
        // }, //上传文件类型
        uploadHeaders: {
            type: Object,
            default: () => {
                return {}
            },
        },
        showView: {
            //上传视频时是否需要查看大图
            type: Boolean,
            default: false,
        },
        showDel: {
            //上传视频时是否需要删除视频
            type: Boolean,
            default: true,
        },
        defaultList: Array, //默认上传列表
        fileLength: {
            // 最多上个文件个数
            type: [String, Number],
            default: 1,
        },
        target: {
            type: [String, Number],
            default: null,
        },
    },
    data() {
        return {
            uploadList: [],
            action: "",
            uploadData: {},
            uploadNamePath: [],
            visible: false,
            visibleUrl: "",
        }
    },
    methods: {
        handleBeforeUpload(file) {
            const check = this.uploadList.length < parseInt(this.fileLength)
            if (!check) {
                this.$Notice.warning({
                    title: "最多只能上传" + this.fileLength + (this.type == "video" ? "张视频" : "个文件"),
                })
                return check
            } else {
                return this.$get("/datamsg/api/common/file/getWebPolicy?bucketName=liefeng").then(res => {
                    if (res.code == 200) {
                        let now = this.$core.randomNumber()
                        //防止文件名有.
                        let suffixArr = file.name.split(".")
                        let suffix = "." + suffixArr[suffixArr.length - 1]
                        this.action = res.data.host
                        this.uploadData = {
                            name: now + suffix,
                            host: res.data.host,
                            key: res.data.key + now + suffix,
                            policy: res.data.encodedPolicy,
                            OSSAccessKeyId: res.data.accessId,
                            success_action_status: "200",
                            signature: res.data.postSignature,
                        }
                        this.uploadNamePath.push({
                            url: res.data.host + res.data.key + this.uploadData.name,
                            name: this.uploadData.name,
                        })
                    }
                })
            }
        },
        handleSuccess(res, file) {
            if (file.status == "finished") {
                file.url = this.uploadNamePath[this.uploadNamePath.length - 1].url
                file.name = this.uploadNamePath[this.uploadNamePath.length - 1].name
                this.uploadNamePath.splice(this.uploadNamePath.length - 1, 1)
            } else {
                this.uploadNamePath.splice(this.uploadNamePath.length - 1, 1)
                const fileList = this.$refs.upload.fileList
                this.$refs.upload.fileList.splice(fileList.indexOf(file), 1)
                this.$Message["error"]({
                    background: true,
                    content: "视频上传失败！",
                })
            }
            //返回
            if (this.target != null) {
                this.defaultList.push({
                    url: file.url,
                    name: file.name,
                    status: "finished",
                })
                this.$refs.upload.fileList = this.defaultList
                this.$emit("success", this.target, file)
            }
        },
        handleFormatError(file) {
            this.$Notice.warning({
                title: "上传失败",
                desc: "文件" + file.name + "格式错误，请上传" + this.format.join(",") + "格式的" + (this.type == "video" ? "视频" : "文件"),
            })
        },
        handleMaxSize(file) {
            this.$Notice.warning({
                title: "上传失败",
                desc: "文件  " + file.name + " 大小超出限制，请上传" + this.maxSize / 1024 + "M以内的" + (this.type == "video" ? "视频" : "文件"),
            })
        },
        handleView(url) {
            this.visibleUrl = url
            this.visible = true
        },
        handleRemove(file) {
            const fileList = this.$refs.upload.fileList
            this.$refs.upload.fileList.splice(fileList.indexOf(file), 1)
            this.uploadList = this.$refs.upload.fileList
            //返回
            if (this.target != null) {
                this.$emit("success", this.target, {})
            }
        },
    },
    mounted() {
        this.uploadList = this.$refs.upload.fileList
    },
    components: {
        LiefengModal,
    },
}
</script>
    
<style scoped lang='less'>
.demo-upload-list {
    display: inline-block;
    width: 84px;
    height: 84px;
    text-align: center;
    line-height: 84px;
    border: 1px solid transparent;
    border-radius: 4px;
    overflow: hidden;
    background: #fff;
    position: relative;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
    margin-right: 4px;
}
.demo-upload-list img {
    width: 100%;
    height: 100%;
}
.demo-upload-list-cover {
    display: none;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.6);
}
.demo-upload-list:hover .demo-upload-list-cover {
    display: block;
}
.demo-upload-list-cover i {
    color: #fff;
    font-size: 20px;
    cursor: pointer;
    margin: 0 2px;
}
</style>